import ApplicationLogo from "../components/ApplicationLogo";
import { Link } from "@inertiajs/react";

export default function Guest({ children }) {
    return (
        <div
            className="min-h-screen bg-auto bg-repeat flex flex-col sm:justify-center items-center pt-6 sm:pt-0"
            style={{ backgroundImage: "url('/images/background.svg')" }}
        >
            <div>
                <Link href="/">
                    <ApplicationLogo className="h-14 w-auto" />
                </Link>
            </div>

            <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-island-spice text-astronaut-blue shadow-md overflow-hidden sm:rounded-3xl">
                {children}
            </div>
        </div>
    );
}
